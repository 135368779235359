import classNames from "classnames";
import {DrupalMenuLinkContent} from "next-drupal";
import Link from "next/link";
import Image from "next/image";
import {useLayout, useMediaQuery} from "@lib/hooks";
import {Menu, MobileMenu, SocialMenu} from "../components/Menu";
import {LanguageSwitcher, SearchBlock} from "../components";
import React, {useEffect} from "react";
import ArrowRightIcon from "@icons/arrow-right.svg";
interface HeaderProps {
  mainMenu: DrupalMenuLinkContent[];
  socialMenu: DrupalMenuLinkContent[];
  menuToTop: boolean;
  hidemenu: boolean;
}

// TODO : Update style to sass

const Header = ({mainMenu, socialMenu, menuToTop, hidemenu}: HeaderProps) => {
  const isLaptop = useMediaQuery(1200);
  const {resource} = useLayout();
  const [showMenuMobile, setShowMenuMobile] = React.useState<boolean>(false);
  const [showMenuChildren, setShowMenuChildren] = React.useState<boolean>(false);
  const [menuLinks, setMenuLinks] = React.useState<DrupalMenuLinkContent[]>([]);
  const isHomepage = (resource.canonical as string).includes("/node/1");
  const headerClasses = () => {
    let className = "";
    if (!isHomepage) {
      if (menuToTop && !hidemenu) {
        className = "not-homepage header-top";
      } else {
        className = "not-homepage header-scroll";
      }
    } else {
      if (menuToTop) {
        className = "homepage header-top";
      } else {
        className = "homepage";
      }
    }
    return className;
  };
  useEffect(() => {
    if (isLaptop) {
      setShowMenuMobile(false);
      setShowMenuChildren(false);
      setMenuLinks([]);
    }
  }, []);

  return (
    <header className={classNames("header header-pattern", isLaptop && "!block", headerClasses())}>
      <div className="container mx-auto">
        <nav className={classNames("navbar navbar-top", !menuToTop && "navbar-base")}>
          {showMenuChildren ? (
            <div className="mobile-backword">
              <ArrowRightIcon
                onClick={() => {
                  setShowMenuChildren(false);
                  setMenuLinks(mainMenu);
                }}
              />
            </div>
          ) : (
            <div className="navbar-brand">
              <Link aria-label="Logo" href="/" prefetch={false}>
                <Image src="/Logo.svg" fill alt="Logo" />
              </Link>
            </div>
          )}

          <div className="navbar-left  ">
            {!showMenuChildren && <SearchBlock />}
            {!showMenuChildren && <LanguageSwitcher />}
            {isLaptop && (
              <MobileMenu
                mobileMenu={{
                  setShowMenuMobile,
                  showMenuMobile,
                }}
                childrenMenu={{
                  setShowMenuChildren,
                  showMenuChildren,
                }}
                setMenuLinks={setMenuLinks}
                menuContent={menuLinks.length > 0 ? menuLinks : mainMenu}
              />
            )}
            {!isLaptop && <SocialMenu variant="blue" socialMenu={socialMenu} />}
          </div>
        </nav>
      </div>
      <div
        className={classNames("block w-full bg-primary [&>*]:text-white ", {
          hidden: isHomepage,
        })}
      >
        <Menu menuContent={mainMenu} menuToTop={menuToTop} hidemenu={hidemenu} />
      </div>
    </header>
  );
};
export default Header;
