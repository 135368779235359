import {DrupalLink} from "@components/fields";
import NewsletterSubscriber from "@components/general/NewsLetter";
import {useLayout, useMediaQuery} from "@lib/hooks";
import {t} from "@lib/translations-provider";
import {DrupalMenuLinkContent} from "next-drupal";
import Image from "next/image";
import ContactUs from "../components/ContactUs";
import {CopyRightMenu, FooterMenu, SocialMenu} from "../components/Menu";

interface FooterProps {
  footerMenu: DrupalMenuLinkContent[];
  socialMenu: DrupalMenuLinkContent[];
  copyRightMenu: DrupalMenuLinkContent[];
}

const Footer = ({footerMenu, socialMenu, copyRightMenu}: FooterProps) => {
  const {blocks} = useLayout();
  const media = useMediaQuery(1024);
  return (
    <footer className="footer footer-pattern">
      <div className="container">
        <div className="footer-top-section ">
          <div className="footer-brand">
            <DrupalLink variant="minimal" aria-label="Logo" link="/">
              <Image src="/white-logo.svg" fill alt="Logo" />
            </DrupalLink>
          </div>
          {!media && <NewsletterSubscriber view="footer" />}
        </div>
        <div className="menu-container">
          {/* renderes footer menu  */}
          <FooterMenu menuContent={footerMenu} />
          {blocks.contact_us && <ContactUs block={blocks.contact_us} />}
        </div>
        {media && <NewsletterSubscriber view="footer" />}
        <div className="social-media-container">
          {/* social media tools  */}
          <div className="social-wrapper">
            <SocialMenu variant="white" socialMenu={socialMenu} />
          </div>
          <div className="vision-wrapper ">
            <div className="digital-gov">
              <Image src="/digital_government.svg" fill alt="digital_government" />
            </div>
            <div className="vision">
              <Image src="/vision2030.svg" fill alt="vision2030" />
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        <div className="container">
          <div className="copy-right-wrapper">
            <div className="copy-right-text max-mobile:text-center">
              {t("© {{year}} All rights reserved to Ministry of Human Resource and Social Development", {
                year: new Date().getFullYear(),
              })}
            </div>
            <CopyRightMenu className="copy-right-menu" menuContent={copyRightMenu} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
