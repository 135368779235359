import {Body} from "@components/fields";
import {useMediaQuery} from "@lib/hooks";
import {t} from "@lib/translations-provider";
import {SeedsPargraphBlock} from "@type/block";
import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import {AccordionContent, AccordionItem, AccordionTrigger} from "../Menu/footer-menu/MenuAccordion";
type ContactUsProps = {
  block: SeedsPargraphBlock;
};

const ContactUs = (props: ContactUsProps) => {
  const {block} = props;
  const media = useMediaQuery(1023);

  return (
    <div className="contactus-block laptop:flex-[17%] laptop:pt-[32px] max-laptop:w-full max-laptop:flex-[100%] flex max-laptop:gap-10 flex-col flex-wrap text-white contact-us-block">
      {media ? (
        <Accordion.Root type="single" defaultValue={block.id} collapsible>
          <AccordionItem value={block.id}>
            <AccordionTrigger>{t(block.field_seeds_single_paragraph.field_seeds_title)}</AccordionTrigger>
            <AccordionContent>
              <Body
                className="text-[24px] font-somer flex flex-row flex-wrap max-laptop:gap-3 contact-us"
                body={block.field_seeds_single_paragraph.field_seeds_text}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion.Root>
      ) : (
        <>
          <span className="text-[26px] font-[700] pb-[16px] block">
            {t(block.field_seeds_single_paragraph.field_seeds_title)}
          </span>
          <Body
            className="text-[24px] font-somer flex flex-row flex-wrap max-laptop:gap-3 contact-us"
            body={block.field_seeds_single_paragraph.field_seeds_text}
          />
        </>
      )}
    </div>
  );
};

export default ContactUs;
