import {Heading} from "@components/elements";
import {useLayout, useMediaQuery} from "@lib/hooks";
import {PropsWithChildren, useEffect, useRef, useState} from "react";
import {hasRating, isDrupalPage, isDrupalTerm} from "@lib/utils";
import {Date} from "@components/fields";
import {Footer, Header} from "./partial";
import TitlePattern from "@icons/title-pattern.svg";
import {
  AccessibilityTools,
  PageAccessories,
  ThirdLevelNavigation,
  RatingProvider,
  Breadcrumbs,
  StickyMenu,
} from "./components";
import classNames from "classnames";
import {WhiteMenu} from "./components/Menu/white-sticky-menu";
export function Layout({children}: PropsWithChildren<{}>) {
  const {mainMenu, breadcrumbs, resource, isFront, socialMenu, footerMenu, copyRightMenu} = useLayout();
  const menuRef = useRef<HTMLElement>(null);
  const [menuRefPosition, setMenuRefPosition] = useState(menuRef.current?.offsetTop ?? 0);
  const [menuToTop, setMenuToTop] = useState(isFront ? false : true);
  const [hidemenu, setHideMenu] = useState(false);
  const isDesktop = useMediaQuery(1200);
  const mainContentRef = useRef<HTMLDivElement>(null);
  let hideBreadcrumb = isFront;
  let showRatingStars = false;
  let showRatingUseful = false;
  if (resource && hasRating(resource)) {
    showRatingStars = resource.field_rating_stars ?? true;
    showRatingUseful = resource.field_rating_useful ?? true;

    if (!isFront) {
      hideBreadcrumb = resource.hide_breadcrumb !== null && resource.hide_breadcrumb;
    }
  }
  const handleScroll = (e: Event) => {
    const position = window.pageYOffset;
    if (resource) {
      if (isDrupalPage(resource)) {
        if (isFront) {
          if (menuToTop == false && menuRef?.current != null) {
            if (position > menuRefPosition) {
              setMenuToTop(true);
            } else {
              setMenuToTop(false);
            }
          } else {
            if (position > menuRefPosition) {
              setMenuToTop(true);
            } else {
              setMenuToTop(false);
            }
          }
        } else if (position > menuRefPosition) {
          setHideMenu(true);
        } else {
          setHideMenu(false);
        }
      } else {
        if (position > menuRefPosition) {
          setHideMenu(true);
        } else {
          setHideMenu(false);
        }
      }
    }
  };

  const renderTitle = () => {
    let title: string = "";

    /**
     * this is a special case for the custom route
     * that we had to make for the custom route not to copy the client's DB
     * ref horse_landing
     */
    if (resource.name === "CUSTOM_ROUTE_NO_TITTLE") {
      return null;
    }

    if (isDrupalPage(resource)) {
      title = resource.title;
    } else if (isDrupalTerm(resource)) {
      title = resource.name;
    }

    return (
      title != "" &&
      !resource.field_boolean_2 &&
      !isFront && (
        <div className="container mx-auto">
          <Heading className="mb-8 mt-10" icon={TitlePattern} iconPosition={"right"} element="h2" type="medium">
            {title}
          </Heading>
        </div>
      )
    );
  };

  useEffect(() => {
    if (menuRef.current != null && menuRefPosition == 0) {
      setMenuRefPosition(menuRef.current?.offsetTop);
    }
    if (resource && isDrupalPage(resource)) {
      if (!isFront) {
        setMenuToTop(true);
      }
    }
    if (!isDrupalPage(resource)) {
      setMenuToTop(true);
      window.addEventListener("scroll", handleScroll, {passive: true});
    } else {
      window.addEventListener("scroll", handleScroll, {passive: true});
    }
  }, []);

  return (
    <>
      {/* Header */}
      <Header mainMenu={mainMenu} socialMenu={socialMenu} menuToTop={menuToTop} hidemenu={hidemenu} />

      {!isDesktop && (
        <>
          <WhiteMenu menuContent={mainMenu} menuToTop={menuToTop} hidemenu={hidemenu} />

          <StickyMenu
            ref={menuRef}
            menuContent={mainMenu}
            menuToTop={menuToTop}
            setMenuToTop={setMenuToTop}
            handleScroll={handleScroll}
            hidemenu={hidemenu}
          />
        </>
      )}
      {/* Breadcrumb */}
      {!(resource.canonical as string).includes("/node/1") && breadcrumbs && !hideBreadcrumb && (
        <div
          className={classNames(
            "bg-gray-lighter py-3 relative z-[2] max-laptop:relative",
            (resource.type === "node--service_catalog" || resource.type == "node--championship") && "bg-transparent",
          )}
        >
          <div className="container mx-auto">
            <div
              className={classNames(
                "flex items-center justify-between flex-wrap max-laptop:gap-3 left-[15px] right-[15px]",
                (resource.type === "node--service_catalog" || resource.type == "node--championship") &&
                  "max-laptop:absolute",
              )}
            >
              <Breadcrumbs type={["node--championship", "node--service_catalog"]} breadcrumbs={breadcrumbs} />
              <PageAccessories type={["node--championship", "node--service_catalog"]} mainContentRef={mainContentRef} />
            </div>
          </div>
        </div>
      )}
      {/* Third level navigation */}
      {/* Main Content */}
      <div className="page-pattern">
        {renderTitle()}
        <ThirdLevelNavigation />
        <div ref={mainContentRef}>
          {children}
        </div>
        <div className="footer-bottom">
          {/* Rating Section */}
          <div className="container">
            {resource && (
              <RatingProvider resource={resource}>
                <div className="flex justify-between py-[40px] flex-wrap gap-6 laptop:gap-0">
                  {showRatingStars && <RatingProvider.Stars />}
                  <div className="lastUpdateDate" suppressHydrationWarning>
                    <Date className="date" variant="changed" date={resource.changed} />
                  </div>
                </div>
                <div>{showRatingUseful && <RatingProvider.Useful />}</div>
              </RatingProvider>
            )}
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer footerMenu={footerMenu} socialMenu={socialMenu} copyRightMenu={copyRightMenu} />
      {/* Accessibility tools */}
      <AccessibilityTools />
    </>
  );
}
